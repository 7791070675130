import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: ${(props) => props.theme.fonts?.primary};
  font-size: ${(props) => props.theme.fontSizes?.regular};

  width: 100%;

  padding: 0 1.25rem;

  background-color: ${(props) => props.theme.colors?.base50};
  box-shadow: ${(props) => props.theme.boxShadows?.small};
  transition: all 0.2s ease-in-out;
  font-family: ${(props) => props.theme.fonts?.primary};
  font-size: 1.125rem;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1400px;
    margin: 2rem 0;

    @media ${DEVICE.tablet} {
      margin: 0;
      flex-direction: row;
      align-items: center;
    }

    figure {
      margin: 0;
      cursor: pointer;
    }

    > div {
      color: #000;
      display: flex;
    }
  }

  @media ${DEVICE.mobileS} {
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 45px;
    }
  }

  @media ${DEVICE.laptopL} {
    height: 7.188rem;

    figure {
      width: 230px;
      height: 45px;
    }
  }

  @media ${DEVICE.desktopL} {
    padding: 0 120px;
  }

  a,
  button {
    transition: all ease-in-out 0.2s;
  }

  a:hover,
  a:focus,
  button:hover,
  button:focus {
    filter: brightness(0.8);
  }
`

export const SocialAddresses = styled.address`
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.125rem;
    list-style: none;
    padding: 0 2.625rem;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`
