import React, { TextareaHTMLAttributes } from 'react'

import { Container, TextareaContainer, ErrorMessage } from './styles'

import { Control, useController } from 'react-hook-form'
import { FormValues } from '@/types/contactForm'

interface TextaraProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  control: Control<FormValues>
  name: keyof FormValues
}

export function Textarea({ control, label, name, id, ...rest }: TextaraProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <Container>
      <TextareaContainer invalid={invalid} data-has-value={!!value}>
        {typeof value === 'string' && <textarea
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          id={id}
          ref={ref}
          {...rest}
        />}
        <label htmlFor={id}>{label}</label>
      </TextareaContainer>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  )
}
