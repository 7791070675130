import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import { Container, SocialAddresses } from './styles'

import LogoInovando from '@/assets/images/logo_inovando_completa.svg'

import { NETWORKS_ENTRIES } from '@/utils/constants/socialNetworks'

import { useLanguage } from '@/hooks/useLanguage'

import { SocialItem } from '@/components/atoms/SocialItem'

export function Footer() {
  const { content } = useLanguage()

  return (
    <Container data-testid="footer">
      <div>
        <figure>
          <Link href='/'>
            <Image
              src={LogoInovando}
              width={230}
              height={45}
              alt={content.header.alt}
            />
          </Link>
        </figure>

        <p>{content.footer.message}</p>

        <div>
          <span>&copy; Inovan.do</span>
          <SocialAddresses>
            <ul>
              {NETWORKS_ENTRIES.map(([key, value], index) => (
                <SocialItem
                  title={key}
                  key={`${key} - ${value.url} - ${index}`}
                  href={value.url}
                  icon={value.icon}
                  iconColor="base400"
                  variant="small"
                />
              ))}
            </ul>
          </SocialAddresses>
        </div>
      </div>
    </Container>
  )
}
