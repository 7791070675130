import React, { InputHTMLAttributes } from 'react'

import { Container, InputContainer, ErrorMessage } from './styles'
import { Control, useController } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { FormValues } from '@/types/contactForm'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  control: Control<FormValues>
  name: keyof FormValues
  mask?: string
}

export function Input({
  type,
  control,
  label,
  name,
  id,
  mask,
  disabled,
  ...rest
}: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <Container>
      <InputContainer disabled={!!disabled} invalid={invalid} data-has-value={!!value}>
        {typeof value === 'string' && (mask ? (
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            id={id}
            type={type}
            ref={ref}
            {...rest}
          />
        ) : (
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            id={id}
            type={type}
            ref={ref}
            {...rest}
          />
        ))}
        <label htmlFor={id}>{label}</label>
      </InputContainer>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  )
}
