import React from 'react'
import { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import Image from 'next/image'

import { Container } from './styles'

import { VscGlobe } from 'react-icons/vsc'

import BrazilFlagIcon from '@/assets/icons/brazil_flag_icon.png'
import USAFlagIcon from '@/assets/icons/flag_usa_icon.png'
import Link from 'next/link'
import { useMediaQuery } from 'usehooks-ts'

interface ILanguageMenuProps {
  buttonText: string
}

export function LanguageMenu({ buttonText }: ILanguageMenuProps) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const matches = useMediaQuery('(max-width: 768px)')

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: matches ? 'left-start' : 'bottom-start',
    strategy: 'fixed',
  })
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMenuOpen = () => setIsOpen((open) => !open)

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <Container data-testid="language-menu">
      <button
        data-testid="language-menu-button"
        aria-labelledby="menu-toggle"
        aria-controls="menu-toggle"
        aria-expanded="false"
        aria-haspopup="true"
        onClick={handleToggleMenuOpen}
        ref={setReferenceElement}
      >
        <VscGlobe />
        <span>{buttonText}</span>
      </button>

      <menu
        data-testid="language-menu-popover"
        id="menu-toggle"
        aria-hidden={isOpen}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <li>
          <Link href={'/'} locale="pt-BR">
            <a tabIndex={0}>
              <Image
                src={BrazilFlagIcon}
                alt="Bandeira do Brasil - Idioma: Português"
                width={32}
                height={32}
              />
              <abbr title="Português do Brasil">PT-BR</abbr>
            </a>
          </Link>
        </li>
        <li>
          <Link href={''} locale="en-US">
            <a tabIndex={0}>
              <Image
                src={USAFlagIcon}
                alt="Bandeira dos Estados Unidos - Idioma: Inglês"
                width={32}
                height={32}
              />
              <abbr title="Inglês do Estados Unidos">EN-US</abbr>
            </a>
          </Link>
        </li>
      </menu>
    </Container>
  )
}
