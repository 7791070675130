import React, { AnchorHTMLAttributes } from 'react';

import { IconType } from 'react-icons';

import { Container } from './styles';

import { ISocialItemStyleProps } from './types';

interface ISocialItemProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    ISocialItemStyleProps {
  icon: IconType;
}

export const SocialItem = ({
  icon,
  variant = 'small',
  iconColor = 'base100',
  ...rest
}: ISocialItemProps) => {
  return (
    <Container
      variant={variant}
      iconColor={iconColor}
      data-testid="social-item"
    >
      <a target="_blank" {...rest}>
        {React.createElement(icon)}
      </a>
    </Container>
  );
};
