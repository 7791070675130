import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.aside`
  .background {
    z-index: 0;
  }

  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  overflow: hidden;
  left: 0;
  z-index: 50;
  background-image: linear-gradient(rgb(31, 161, 227), rgb(31, 161, 227));
  background-size: cover;
  background-blend-mode: multiply;
  transition: all linear 400ms;

  &[aria-hidden='false'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    visibility: visible;
    opacity: 1;
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
  }

  > div {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(31, 161, 227, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > header {
      width: 100%;
      max-width: 1500px;
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1.75rem 1.75rem 0;

      @media ${DEVICE.tablet} {
        padding: 3.125rem 2.625rem 0;
      }

      @media ${DEVICE.laptopL} {
        padding: 3.125rem 0rem 0;
      }

      > button {
        font-family: ${(props) => props.theme.fonts?.primary};
        color: ${(props) => props.theme.colors?.base50};
        font-size: 1rem;
        border: none;
        font-weight: 600;
        cursor: pointer;

        svg {
          font-size: 1.25rem;
          height: 1.25rem;
          width: 1.25rem;
          margin-left: 0.625rem;
        }
      }
    }

    > nav {
      width: 100%;
      max-width: 1000px;
      padding: 0 3.125rem;
    }

    > footer {
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3.125rem 1.75rem;

      @media ${DEVICE.laptopL} {
        padding: 0 3.125rem 6rem;
      }
    }
  }

  video {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`

export const Menu = styled.menu`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  list-style: none;
  gap: 0.9rem;
  margin-top: 3.75rem;

  @media ${DEVICE.tablet} {
    gap: 1.563rem;
  }

  > li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-family: ${(props) => props.theme.fonts?.primary};

    @media ${DEVICE.tablet} {
      font-size: 1.375rem;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1.25rem;
      color: ${(props) => props.theme.colors?.base50};
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    a {
      transition: padding-left 200ms ease-in-out;
    }

    &:hover,
    &:focus {
      a {
        padding-left: 24px;
      }

      &::before {
        transition: background 200ms ease-in-out;
        transform: translate(-100%, 0);
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
      }
    }
  }
`

export const SocialAddresses = styled.address`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 400px;

  font-style: normal;

  > ul {
    list-style: none;
    display: flex;
    gap: 1.125rem;
    padding: 0;
  }

  > p {
    font-size: 1rem;
    font-family: ${(props) => props.theme.fonts?.primary};
    color: ${(props) => props.theme.colors?.base50};
    opacity: 0.8;
  }
`
