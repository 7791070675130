import styled from 'styled-components';

import { ISocialItemStyleProps } from './types';
import { THEME } from '@/styles/themes/default';

const SIZES = {
  small: '20px',
  medium: '25px',
};

export const Container = styled.li<ISocialItemStyleProps>`
  a {
    text-decoration: none;
  }

  svg {
    width: ${(props) => SIZES[props.variant]};
    height: ${(props) => SIZES[props.variant]};
    color: ${(props) => THEME.colors[props.iconColor]};
    cursor: pointer;
    font-size: ${(props) => SIZES[props.variant]};
  }
`;
