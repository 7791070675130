import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
} from 'react-icons/ai';

const FACEBOOK = 'https://www.facebook.com/InovandoSoftware';
const INSTAGRAM = 'https://www.instagram.com/inovan.do/';
const LINKEDIN = 'https://www.linkedin.com/company/inovandosoftware/mycompany/';

const NETWORKS = {
  Facebook: {
    icon: AiFillFacebook,
    url: FACEBOOK,
  },
  Linkedin: {
    icon: AiFillLinkedin,
    url: LINKEDIN,
  },
  Instagram: {
    icon: AiOutlineInstagram,
    url: INSTAGRAM,
  },
};

export const NETWORKS_ENTRIES = Object.entries(NETWORKS);
