import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${(props) => props.theme.fonts?.primary};
  font-size: ${(props) => props.theme.fontSizes?.regular};

  width: 100%;

  padding: 0 1.438rem 0 1.25rem;

  background-color: ${(props) => props.theme.colors?.base50};
  box-shadow: ${(props) => props.theme.boxShadows?.small};
  position: sticky;
  top: 0;
  z-index: 30;
  transition: all 0.2s ease-in-out;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    figure {
      margin: 0;
    }
  }

  @media ${DEVICE.mobileS} {
    height: 5rem;

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 45px;
      cursor: pointer;
    }
  }

  @media ${DEVICE.tablet} {
    padding: 0 7.5rem 0 2.688rem;
  }

  @media ${DEVICE.laptopL} {
    height: 7.188rem;

    figure {
      width: 230px;
      height: 45px;
    }
  }

  @media ${DEVICE.desktopL} {
    padding: 0 2.688rem;
  }

  a,
  button {
    transition: all ease-in-out 0.2s;
  }

  a:hover,
  a:focus,
  button:hover,
  button:focus {
    filter: brightness(0.8);
  }
`

export const NavOptions = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
  }

  @media ${DEVICE.mobileS} {
    gap: 1rem;
  }

  @media ${DEVICE.tablet} {
    gap: 2.625rem;
  }
`

export const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors?.primary};
  border-radius: 4px;
  padding: 0.344rem 1.5rem;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors?.base50};
  display: none;
  font-weight: 600;

  @media ${DEVICE.laptop} {
    padding: 0.688rem 1.5rem;
    display: flex;
  }
`

export const SocialAddresses = styled.address`
  display: none;

  ul {
    gap: 1.125rem;
    list-style: none;
    padding: 0 2.625rem;
    border-left: 1px solid ${(props) => props.theme.colors?.base100};
    border-right: 1px solid ${(props) => props.theme.colors?.base100};
    height: 3.125rem;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @media ${DEVICE.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25px;
  padding: 0;
  border: none;
  height: 16px;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    border-radius: ${(props) => props.theme.borderRadius?.thin};
    background-color: ${(props) => props.theme.colors?.base100};
  }
`
