import React, { useEffect } from 'react'
import Image from 'next/image'

import { Container, Menu, SocialAddresses } from './styles'

import { IoClose } from 'react-icons/io5'

import { SocialItem } from '@/components/atoms/SocialItem'

import { NETWORKS_ENTRIES } from '@/utils/constants/socialNetworks'
import { SECTION_PATHS } from '@/utils/constants/sectionPaths'

import LogoWhiteComplete from '@/assets/icons/inovando-horizontal-negativo.svg'
import LogoWhite from '@/assets/icons/inovando-icon.svg'
import Lamp from '@/assets/icons/lamp.svg'
import Technology from '@/assets/icons/technology.svg'
import Diamond from '@/assets/icons/diamond.svg'
import People from '@/assets/icons/people.svg'
import Email from '@/assets/icons/mail.svg'
import Bag from '@/assets/icons/bag.svg'
import Padlock from '@/assets/icons/padlock.svg'
import Github from '@/assets/icons/github.svg'

import { useLanguage } from '@/hooks/useLanguage'
import { useRouter } from 'next/router'
import Link from 'next/link'

interface ISidebar {
  isOpen: boolean
  onClose: () => void
}

export function Sidebar({ isOpen, onClose }: ISidebar) {
  const { content } = useLanguage()
  const { locale } = useRouter()

  const formatLinkToSidebar = (link: string) => {
    return `${locale}/#${link}`
  }

  const MENU_ITEMS = [
    {
      text: content.sidebar.about.text,
      icon: LogoWhite,
      alt: content.sidebar.about.alt,
      to: formatLinkToSidebar(SECTION_PATHS.about),
    },
    {
      text: content.sidebar.ourSolutions.text,
      icon: Lamp,
      alt: content.sidebar.ourSolutions.alt,
      to: formatLinkToSidebar(SECTION_PATHS.ourSolutions),
    },
    {
      text: content.sidebar.technologies.text,
      icon: Technology,
      alt: content.sidebar.technologies.alt,
      to: formatLinkToSidebar(SECTION_PATHS.technology),
    },
    {
      text: content.sidebar.cases.text,
      icon: Diamond,
      alt: content.sidebar.cases.alt,
      to: formatLinkToSidebar(SECTION_PATHS.cases),
    },
    {
      text: content.sidebar.team.text,
      icon: People,
      alt: content.sidebar.team.alt,
      to: formatLinkToSidebar(SECTION_PATHS.team),
    },
    {
      text: content.sidebar.contact.text,
      icon: Email,
      alt: content.sidebar.contact.alt,
      to: formatLinkToSidebar(SECTION_PATHS.contact),
    },
    {
      text: content.sidebar.workWithUs.text,
      icon: Bag,
      alt: content.sidebar.workWithUs.alt,
      to: 'mailto:contato@inovan.do?CC=Trabalhe na Inovan.do',
    },
    {
      text: content.sidebar.privacyPolicy.text,
      icon: Padlock,
      alt: content.sidebar.privacyPolicy.alt,
      to: '/privacy-policy',
    },
    {
      text: 'Open Source',
      icon: Github,
      alt: 'Open Source',
      to: 'https://github.com/inovando',
      target: '_blank',
    },
  ]

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  return (
    <Container aria-hidden={!isOpen} data-testid="sidebar">

      <video className="background" autoPlay loop muted playsInline>
        <source src='/background_sidebar.webm' type="video/webm" />
        <source src='/background_sidebar.mp4' type="video/mp4" />
      </video>

      <div>
        <header>
          <button
            data-testid="sidebar-toggle"
            data-message={content.sidebar.backButton.dataMessage}
            onClick={onClose}
            aria-label={content.sidebar.backButton.ariaLabel}
          >
            {content.sidebar.backButton.text}
            <IoClose />
          </button>
        </header>

        <nav>
          <div>
            <Image
              width={311}
              height={63}
              src={LogoWhiteComplete}
              alt="Logo Inovando"
            />
          </div>

          <Menu type="toolbar">
            {MENU_ITEMS.map((item, index) => (
              <li onClick={() => onClose()} key={`${item.alt} - ${index}`}>
                <Link href={item.to} >
                  <a target={item?.target}>
                    <Image
                      width={20}
                      height={20}
                      src={item.icon}
                      alt={item.alt}
                    />
                    <span>{item.text}</span>
                  </a>
                </Link>
              </li>
            ))}
          </Menu>
        </nav>

        <footer>
          <SocialAddresses>
            <ul>
              {NETWORKS_ENTRIES.map(([key, value], index) => (
                <SocialItem
                  title={key}
                  key={`${key} - ${value.url} - ${index}`}
                  href={value.url}
                  icon={value.icon}
                  iconColor="base50"
                  variant="medium"
                />
              ))}
            </ul>

            <p>&copy;{new Date().getFullYear()} Inovan.do</p>
          </SocialAddresses>
        </footer>
      </div>
    </Container >
  )
}
