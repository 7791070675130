import { DEVICE } from '@/utils/constants/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  > button {
    background: transparent;
    border: none;
    color: #809fb8;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
      @media ${DEVICE.tablet} {
        display: block;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  menu[aria-hidden='false'] {
    display: none;
  }

  menu[aria-hidden='true'] {
    display: flex;
  }

  menu {
    width: 154px;
    height: 116px;
    background: ${(props) => props.theme.colors?.base50};
    box-shadow: ${(props) => props.theme.boxShadows?.small};
    border: 1px solid ${(props) => props.theme.colors?.base100};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    font-family: ${(props) => props.theme.fonts?.primary};
    color: ${(props) => props.theme.colors?.base100};

    abbr {
      text-decoration: none;
    }

    li {
      padding: 0;
      width: 100%;

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: ${(props) => props.theme.colors?.base50};
        color: ${(props) => props.theme.colors?.base100};
        text-decoration: none;
      }

      &::before {
        display: none;
      }

      &:focus,
      &:hover {
        filter: brightness(0.8);
      }
    }

    li + li {
      margin-top: 1rem;
    }
  }
`
