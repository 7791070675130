import { useRouter } from 'next/router'

import { ptBR } from '@/lang/pt-BR'
import { enUS } from '@/lang/en-US'

const LANGUAGES = {
  'pt-BR': ptBR,
  'en-US': enUS,
}

type localesType = keyof typeof LANGUAGES

export function useLanguage() {
  const { locale } = useRouter()
  const content = locale ? LANGUAGES[locale as localesType] : ptBR
  return { content }
}

export type LanguageContent = typeof ptBR
