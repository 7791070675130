import styled from 'styled-components'

export const Container = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 25%;
  background-color: #25d366;
  border: none;
  position: fixed;
  bottom: 90px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #fff;
  z-index: 5000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  svg {
    width: 30px;
    height: 30px;
  }
`
