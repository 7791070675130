import styled from 'styled-components'

import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.div`
  main {
    max-width: 100vw;
  }

  .background {
    z-index: 0;

    &--hero {
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      @media ${DEVICE.desktopL} {
        object-position: right;
      }
    }
  }
`

export const ContactSection = styled.section`
  min-height: 500px;
  position: relative;

  @media (min-width: 1150px) {
    height: 100vh;
  }

  @media ${DEVICE.laptopL} {
    height: 700px;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5.625rem;

    > div {
      min-width: 100%;
      @media ${DEVICE.mobileL} {
        min-width: min-content;
      }
    }

    @media ${DEVICE.mobileL} {
      align-items: center;
    }

    @media (min-width: 1150px) {
      width: max-content;
      position: absolute;
      left: 50%;
      top: 0;
      flex-direction: row;
      transform: translate(-50%, -25%);
      align-items: flex-end;
    }

    address {
      display: flex;
      flex-direction: column;
      font-style: normal;
      gap: 2rem;
      font-family: ${(props) => props.theme.fonts?.primary};
      margin-top: 4rem;

      @media ${DEVICE.laptop} {
        margin: 0;
      }

      > div {
        display: flex;
        flex-direction: column;

        strong {
          font-size: 1.5rem;
          margin-bottom: 6px;
        }

        p {
          font-size: 1.125rem;
        }
      }
    }
  }
`

export const InfoContact = styled.div`
  border-radius: 1.5rem;
  width: 100%;
  background-color: ${(color) => color.theme.colors?.base50};
  padding: 3.5rem 1.25rem;

  form {
    padding: 0;
  }

  @media ${DEVICE.mobileL} {
    padding: 2.75rem 4.5rem;
    max-width: 600px;
  }

  > h2 {
    font-size: 2.625rem;
    font-family: ${(props) => props.theme.fonts?.second};
    max-width: 236px;
    line-height: 42px;
    font-weight: bold;
    letter-spacing: -1px;
    margin: 0;
    margin-bottom: 1.5rem;

    @media ${DEVICE.tablet} {
      font-size: 3.5rem;
      max-width: max-content;
      line-height: 60px;
      margin-bottom: 2rem;
    }
  }

  > p {
    letter-spacing: 0px;
    font-size: 1.125rem;
    font-family: ${(props) => props.theme.fonts?.primary};
    margin: 0;
    max-width: 312px;
    margin-bottom: 2.375rem;

    @media ${DEVICE.laptopL} {
      max-width: max-content;
      & + p {
        margin-top: 1.438rem;
      }
    }
  }
`

export const MapContainer = styled.div`
  width: 100%;

  strong {
    font-family: ${(props) => props.theme.fonts?.primary};
    font-size: 1.5rem;
    padding: 0 4.5rem;
    padding-left: 1.25rem;
    margin: 0;
  }

  @media ${DEVICE.mobileL} {
    padding-left: 1rem;
    max-width: 600px;
  }

  .map-mockup {
    margin-top: 1.438rem;
    width: 100%;
    height: 207px;
    background-color: lightgray;
    border-radius: 0.5rem;
  }
`

export const OnlyMobile = styled.div`
  display: block;
  @media ${DEVICE.tablet} {
    display: none !important;
  }
`

export const OnlyDesktop = styled.div`
  display: none;
  @media ${DEVICE.tablet} {
    display: flex;
  }
`
