import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import {
  Container,
  NavOptions,
  PrimaryButton,
  SocialAddresses,
  BurgerButton,
} from './styles'

import LogoInovando from '@/assets/images/logo_inovando_completa.svg'

import { SocialItem } from '@/components/atoms/SocialItem'
import { LanguageMenu } from '@/components/molecules/LanguageMenu'

import { NETWORKS_ENTRIES } from '@/utils/constants/socialNetworks'

import { useLanguage } from '@/hooks/useLanguage'
import { SECTION_PATHS } from '@/utils/constants/sectionPaths'

interface IHeader {
  onClickBurger: () => void
}

export function Header({ onClickBurger }: IHeader) {
  const { content } = useLanguage()

  return (
    <Container data-testid="header">
      <div>
        <figure>
          <Link href='/'>
            <Image
              src={LogoInovando}
              width={230}
              height={45}
              alt={content.header.alt}
            /></Link>
        </figure>

        <NavOptions>
          <Link href={`#${SECTION_PATHS.contact}`}>
            <PrimaryButton>{content.header.contactButton}</PrimaryButton>
          </Link>

          <SocialAddresses>
            <ul>
              {NETWORKS_ENTRIES.map(([key, value], index) => (
                <SocialItem
                  title={key}
                  key={`${key} - ${value.url} - ${index}`}
                  href={value.url}
                  icon={value.icon}
                  iconColor="base100"
                  variant="small"
                />
              ))}
            </ul>
          </SocialAddresses>

          <LanguageMenu buttonText={content.header.languageToggleButton} />

          <BurgerButton
            aria-label={content.header.burgerButton.ariaLabel}
            data-message={content.header.burgerButton.dataMessage}
            onClick={onClickBurger}
          >
            <div />
            <div />
            <div />
          </BurgerButton>
        </NavOptions>
      </div>
    </Container>
  )
}
