import styled, { css } from 'styled-components'

interface InputContainerProps {
  invalid: boolean
  disabled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors?.base400};
  border-radius: 0.5rem;
  font-family: ${(props) => props.theme.fonts?.primary};

  label {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 1.875rem;
    transform: translate(0, -50%) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: ${(props) => props.theme.colors?.base800};
    font-size: 1.125rem;
    line-height: 1;
    padding: 0 5px;
  }

  &[data-has-value='true'],
  &:focus-within,
  &:hover {
    label {
      top: 0;
      transform: translate(0, -50%) scale(1);
      color: ${(props) => props.theme.colors?.primary};
      background: ${(props) => props.theme.colors?.base50};
    }

    border-color: ${(props) => props.theme.colors?.primary};
  }

  input {
    flex: 1;
    padding: 1.25rem 1.875rem;
    outline: 0;
    background: transparent;
    border-radius: 8px;
    border: 0;
  }
  ${(props) =>
    props.invalid &&
    css`
      border-color: red;
      label {
        color: red;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      filter: grayscale(1);
    `}
`

export const ErrorMessage = styled.span`
  font-family: ${(props) => props.theme.fonts?.primary};
  color: red;
`
