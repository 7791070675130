import { setupYup } from '@/config/yup'

const Yup = setupYup()

export const schema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  message: Yup.string().required(),
  accepted: Yup.boolean().oneOf([true]).required(),
})
