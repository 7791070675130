import React, { ReactNode } from 'react'

import { Header } from '@/components/organisms/Header'
import { Sidebar } from '@/components/organisms/Sidebar'
import { Map } from '@/components/organisms/Map'
import { Footer } from '@/components/organisms/Footer'

import { useBoolean } from 'usehooks-ts'

import {
  Container,
  ContactSection,
  InfoContact,
  MapContainer
} from './styles'

import { ContactForm } from '@/components/organisms/ContactForm'

import { useLanguage } from '@/hooks/useLanguage'
import { sanitize } from 'isomorphic-dompurify'
import { SECTION_PATHS } from '@/utils/constants/sectionPaths'
import WhatsappButton from '@/components/atoms/WhatsappButton'

interface IMainPageProps {
  children: ReactNode
}

export const MainPage = ({ children }: IMainPageProps) => {
  const {
    value: isOpen,
    setFalse: setIsClose,
    toggle: handleToggleMenuOpen
  } = useBoolean();

  const { content } = useLanguage()
  const { contact } = content;

  return (
    <Container data-testid="home-template">
      <Header onClickBurger={handleToggleMenuOpen} />
      <Sidebar
        isOpen={isOpen}
        onClose={setIsClose}
      />
      <main>
        {children}

        <ContactSection data-testid="contact-section" id={SECTION_PATHS.contact}>
          <nav>
            <div>
              <InfoContact>
                <h2>{contact.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: sanitize(contact.firstParagraph) }}></p>

                <p>{contact.secondParagraph}</p>

                <p>{contact.thirdParagraph}</p>

                <address>
                  <div>
                    <strong>{contact.address.phone}</strong>
                    <span>(41) 99729 7016</span>
                  </div>

                  <div>
                    <strong>{contact.address.email}</strong>
                    <span>contato@inovan.do</span>
                  </div>
                </address>
              </InfoContact>

              <MapContainer>
                <strong>{contact.map.title}</strong>
                <Map />
              </MapContainer>
            </div>
            <ContactForm {...contact.form} />
          </nav>
        </ContactSection>
      </main>
      <Footer />
      <WhatsappButton />
    </Container>
  )
}
