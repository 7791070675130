import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '207px',
  borderRadius: '8px',
  marginTop: '23px',
}

const center = {
  lat: -25.4669143,
  lng: -49.2753579,
}

function MyComponent() {
  return (
    <LoadScript
      googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || ''}
    >
      <GoogleMap
        onLoad={(event) => console.log(event)}
        mapContainerStyle={containerStyle}
        options={{ disableDefaultUI: true }}
        center={center}
        zoom={16}
      >
        <Marker
          label="Inovando Software House Soluções LTDA"
          position={center}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export const Map = React.memo(MyComponent)
