import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import { Input } from '@/components/molecules/Input'

import { useForm } from 'react-hook-form'

import { Container, InputGroup, SubmitArea, CheckboxArea } from './styles'
import { Textarea } from '@/components/molecules/Textarea'

import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'

import { FormValues } from '@/types/contactForm'

import { toast } from 'react-toastify'

const messages: Record<string, Record<string, string>> = {
  success: {
    'pt-BR': 'Email enviado com sucesso!',
    'en-US': 'Email sent successfully!'
  },
  error: {
    'pt-BR': 'Algo deu errado, tente novamente mais tarde!',
    'en-US': 'Something went wrong, please try again later!'
  }
}

import { LanguageContent } from '@/hooks/useLanguage';
import Link from 'next/link'

type IContactFormProps = LanguageContent['contact']

export function ContactForm({ title, button, email, message, name, phone, notes, acceptTerms, privacyPolicy }: IContactFormProps['form']) {
  const [token, setToken] = useState('');
  const [refreshReCaptcha] = useState(false);
  const { locale } = useRouter()

  const onVerify = useCallback((token: string) => {
    console.log(token)
    setToken(token);
  }, []);

  const sendMail = async (values: FormValues) => {
    try {
      await axios.post('/api/send-email', { values, token })
      toast.success(messages.success[locale || 'pt-BR'])
    } catch (error) {
      console.error(error)
      toast.error(messages.error[locale || 'pt-BR'])
    }
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      accepted: false
    },
  })

  return (
    <Container noValidate onSubmit={handleSubmit((values) => sendMail(values))}>
      <fieldset disabled={isSubmitting}>
        <legend>{title}</legend>

        <InputGroup>
          <Input
            control={control}
            label={name.placeholder}
            name="name"
            id="name"
            type="text"
            disabled={isSubmitting}
          />
          <Input
            control={control}
            label={email.placeholder}
            name="email"
            id="email"
            type="email"
            disabled={isSubmitting}
          />
          <Input
            control={control}
            label={phone.placeholder}
            name="phone"
            id="phone"
            type="text"
            mask="(99) 99999 9999"
            disabled={isSubmitting}
          />
          <Textarea
            control={control}
            label={message.placeholder}
            name="message"
            id="message"
            disabled={isSubmitting}
          />

          <CheckboxArea data-error={!!errors?.accepted?.message}>
            <input type="checkbox" id='accept-terms' {...register('accepted')} />
            <label htmlFor="accept-terms">{acceptTerms} <Link href='/privacy-policy'>
              <a>{privacyPolicy}</a>
            </Link>
            </label>
          </CheckboxArea>
        </InputGroup>

        <GoogleReCaptcha
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />

        <SubmitArea>
          <p>{notes}</p>
          <button aria-label={button.ariaLabel} data-message={button.dataMessage} type="submit">{isSubmitting ? button.loading : button.text}</button>
        </SubmitArea>
      </fieldset>
    </Container>
  )
}
