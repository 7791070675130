import { DEVICE } from '@/utils/constants/breakpoints'
import styled from 'styled-components'

export const Container = styled.form`
  padding: 0 2rem;

  @media ${DEVICE.tablet} {
    width: 477px;
    padding: 0;
  }

  fieldset {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;

    legend {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 2.313rem;
      font-family: ${(props) => props.theme.fonts?.primary};
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const SubmitArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
  font-family: ${(props) => props.theme.fonts?.primary};

  p {
    font-size: 1.125rem;
    color: ${(props) => props.theme.colors?.base200};
    max-width: 164px;

    @media ${DEVICE.tablet} {
      max-width: max-content;
    }
  }

  button[type='submit'] {
    border: 0;
    background: ${(props) => props.theme.colors?.primary};
    color: ${(props) => props.theme.colors?.base50};
    font-weight: 600;
    font-size: 1.125rem;
    padding: 1.125rem 2.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
      filter: brightness(0.8);
    }

    &:disabled {
      filter: grayscale(1);
    }
  }
`

export const CheckboxArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: ${(props) => props.theme.fonts?.primary};
  font-size: 0.75rem;

  a {
    color: ${(props) => props.theme.colors?.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  input[type='checkbox'] {
    height: 1rem;
    width: 1rem;
  }

  &[data-error='true'] {
    color: red;
    accent-color: red;
    input[type='checkbox'] {
      border: red;
    }
  }
`
