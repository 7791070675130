import React from 'react'
import { Container } from './styles'
import { BsWhatsapp } from 'react-icons/bs'
import Link from 'next/link'

export default function WhatsappButton() {
  const WHATSAPP_LINK = 'https://wa.me/5541997297016?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20empresa.'
  return (
    <Link href={WHATSAPP_LINK} target="_blank">
      <a target='__blank' aria-label='Envia mensagem para conhecer mais sobre os serviços da Inovando pelo Whatsapp.'>
        <Container aria-label='Botão que redireciona usuário para cliente Whatsapp e inicia uma conversa com o time da empresa Inovando'>
          <BsWhatsapp />
        </Container>
      </a>
    </Link>
  )
}
